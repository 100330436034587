<template>
  <section class="section has-background-white">
    <div class="container has-text-centered">
      <h1 class="title is-size-2 has-text-weight-light is-spaced has-space-below">
        {{nameOfFAQ +' '+staticData.title}}
      </h1>
      <h2 class="subtitle is-size-4 has-dbl-space-below">
        {{staticData.subtitle}}
      </h2>
    </div>

    <div class="loading is-loading" v-if="loading">
      Loading...
    </div>

    <div class="notification is-warning has-text-white" v-if="error">
      FAQs cannot be retrieved, error is: {{error}}
    </div>

    <div class="container " v-if="pageData">

      <div class="tags">
        <span class="tag is-info is-medium" v-for="(faqs, category) in categories" :key="category">
          <a class="has-text-white" :href="'#'+category">{{category}}</a></span>
      </div>

      <div class="box" v-for="(faqs, category) in categories" :id="category" :key="category">
        <a :name="category"></a>
        <h3 class="is-size-5">{{category}}</h3>

        <article class="message is-info" v-for="(faq, idx) in faqs" :key="idx">
          <div class="message-header hand" @click="faq.expand = !faq.expand">
            <div class="has-text-white">
              {{faq.fields.question}}
            </div>
            <div class="is-pulled-right">
              <font-awesome-icon :icon="['fas', 'compress-alt']" size="1x" class="has-text-white" v-show="faq.expand"/>
              <font-awesome-icon :icon="['fas', 'expand-alt']" size="1x" class="has-text-white" v-show="!faq.expand"/>
            </div>
          </div>
          <rich-text :document="faq.fields.answer" class="message-body" v-show="faq.expand"></rich-text>
        </article>
      </div>

    </div>
  </section>
</template>

<script>
  import RichText from '@/components/RichText.vue'
  import {mapState} from 'vuex'

  let log; //make logger available to support methods here

  /**
   * processIntoCategories - take the basic contentful FAQs and process into a more
   * easily processed format.
   *
   * @param faqs {Array} - content for faqs returned by contentdul
   * @return {*} - of the form {cat1:[faqs], cat2:[faqs]}
   */
  const processIntoCategories = function (faqs) {
    let cats = faqs
      .map(faq => {
        faq.expand = false;
        return faq;
      }) //add in expand flag
      .reduce((acc, faq /*, idx */) => { //sort into arrays by category
        if (acc[faq.fields.category]) {
          acc[faq.fields.category].push(faq);
        }
        else {
          acc[faq.fields.category] = [faq];
        }
        return acc;
      }, {});
    // log('{processIntoCategories} map:', cats2);
    return cats;
  };


  export default {
    name: 'FAQs',
    props: {
      category: String //supply a category property to limit FAQs to a subset
    },
    data: function () {
      return {
        loading: false,
        error: null,
        pageData: false, //NB v-if uses this to hide div when no data;
        categories: {}, //category names are properties containing array of FAQ
        staticData: {
          title: "FAQs",
          subtitle: "Scroll through our FAQs or click a tag to jump to that category. Click on a question to expand it."
        }
      }
    },
    components: {
      RichText
    },

    beforeCreate() {
      log = this.$log.debug; //make logger available to support methods here
    },

    created() {
      this.getFAQs(this.$route.params.arg); //if we split into a separate component a call could look like this: this.getFAQs("Employer");

    },

    methods: {
      /**
       * getFAQs from Contentful NB the product name is specified in the router URL as 'arg' not 'product'
       *
       * @param category {String} - set to retrieve a particular category of FAQ, optional.
       */
      getFAQs: function (product, category) {
        log('{FAQ} getFAQs called');
        this.error   = null;
        this.faqs    = [];
        this.loading = true;

        let query = {
          skip: 0,
          limit: 40,
          'content_type': process.env.VUE_APP_FAQ_TYPE_ID
        };
        if (product) {
          query['fields.product'] = product;
        }
        if (category) {
          query['fields.category'] = category;
        }
        this.$log.debug('{FAQ} getFAQs query:', JSON.stringify(query, null, 2));
        this.$contentful
          .getEntries(query)
          .then((res) => {
            log('{FAQ} categories result:', res);

            this.categories = processIntoCategories(res.items);
            this.loading    = false;
            this.pageData  = true;
          })
          .catch((error) => {
            this.$log.debug('{FAQ} getFAQs failed with error:', error);
            this.loading = false;
            this.error   = error;
          })
      }
    },

    computed: {
      // Should this really be getters in the menu Vuex store?
      nameOfFAQ: function() {
        //NB this is the way to access Vuex store inside computed properties - reactive
        switch (this.$route.params.arg) {
          case 'untangl-one':
            return 'One';
          case 'untangl-select':
            return 'Select';
          case 'untangler':
            return 'Untangler';
          default:
            return '';
        }
      },

      // mix this into the outer object with the object spread operator
      ...mapState({
        profile: state => state.profile,
        menu: state => state.menu,

      })
    },

    metaInfo() {
      return {
        title: "FAQ",
        meta: [
          {
            property: 'og:title',
            content: "FAQ",
            vmid: 'og:title'
          },
          {
            property: 'og:description',
            content:  'Frequently asked questions for our products',
            vmid: 'og:description'
          },
          {
            vmid: 'description',
            name: 'description',
            content:  'Frequently asked questions for our products'
          },
          {
            vmid: 'robots',
            name: 'robots',
            content: 'index, follow'
          } //noindex
        ]
      }
    }



  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  /*@import "~bulma/sass/utilities/mixins";*/
  /*@import "@/scss/_bulmaOverridden";*/

</style>
